<template>
  <div class="coupon-card" @click="navigateToCouponPage">
    <div v-if="businessLogo" class="business-logo">
      <img :src="businessLogoSrc" alt="Business Logo" />
    </div>
    <div class="coupon-image">
      <img :src="couponImageSrc" alt="Coupon Image" />
    </div>
    <div class="coupon-content">
      <div class="coupon-header">
        <div class="business-rating-row">
          <h3 class="business-name">{{ businessName }}</h3>
          <div class="star-rating">
            <span class="star">★</span>
            <span class="rating-number">{{ rating }}</span>
          </div>
        </div>
        <div class="bussiness-address">{{ businessAddress }}</div>
        <h4 class="deal-name">{{ truncatedDealTitle }}</h4>
      </div>
      <!-- Non-logged in state -->
      <div class="get-deal-button-container" v-if="!isLoggedIn">
        <div 
          class="get-deal-button" 
          @click.stop="handleDealClick"
        >
          <span>SAVE INCENTIVE</span>
        </div>
        <div class="dashed-box">XXXXXX</div>
      </div>
      <!-- Logged in state -->
      <div v-else>
        <div class="coupon-code-container">
          <div class="coupon-code-box">{{ couponCode }}</div>
          <div class="copy-button" @click.stop="copyCode">{{ copyButtonText }}</div>
        </div>
        <div class="coupon-actions" @click.stop>
          <button 
            v-if="!isInShoppingBag"
            class="action-button" 
            @click="saveCoupon" 
            title="Save Incentive"
          >
            <img src="https://img.icons8.com/ios-glyphs/30/1A3819/shopping-bag.png" alt="Save Coupon" class="icon" />
          </button>
          <button 
            v-else
            class="action-button remove" 
            @click="removeCoupon" 
            title="Remove Incentive"
          >
            <img src="https://img.icons8.com/ios-glyphs/30/ffffff/delete-sign.png" alt="Remove Coupon" class="icon" />
          </button>
          <button 
            class="action-button" 
            @click="printCoupon" 
            title="Print Incentive"
          >
            <img src="https://img.icons8.com/ios-glyphs/30/1A3819/print.png" alt="Print Coupon" class="icon" />
          </button>
        </div>
      </div>
      <div v-if="message" class="message" :class="{ fade: fading }">{{ message }}</div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import defaultImage from '@/assets/default-image.png';

export default {
  name: 'CouponCard',
  props: {
    couponId: {
      type: String,
      required: true
    },
    businessName: {
      type: String,
      required: true
    },
    dealTitle: {
      type: String,
      required: true
    },
    rating: {
      type: Number,
      default: 0 // Set default value to 0
    },
    couponImage: {
      type: String,
      default: ''
    },
    couponCode: {
      type: String,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    businessAddress: {
      type: String,
      default: ''
    },
    businessLogo: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      message: '',
      fading: false,
      isLoggedIn: false,
      showFakeCode: false,
      copyButtonText: 'Copy',
      userId: null,
      isInShoppingBag: false, // Add this flag
      token: localStorage.getItem('token'),
    };
  },
  computed: {
    truncatedDealTitle() {
      return this.dealTitle.length > 60 ? this.dealTitle.substring(0, 60) + '...' : this.dealTitle;
    },
    couponImageSrc() {
      return this.couponImage || defaultImage;
    },
    businessLogoSrc() {
      return this.businessLogo || defaultImage;
    }
  },
  mounted() {
    this.isLoggedIn = !!localStorage.getItem('user');
    this.checkIfInShoppingBag(); // Call this method to check if the coupon is in the shopping bag
    window.addEventListener('update-shopping-bag', this.checkIfInShoppingBag);
  },
  beforeUnmount() {
    window.removeEventListener('update-shopping-bag', this.checkIfInShoppingBag);
  },
  methods: {
   async navigateToCouponPage() {
      await this.$router.push(`/incentive/${this.couponId}`).then(() => {
        location.reload();
                window.scrollTo(0, 0);
      });
      this.logInteraction('click');
    },
    saveCoupon(event) {
      event.stopPropagation();
      const userId = JSON.parse(localStorage.getItem('user'));
      if (this.isLoggedIn) {
        let shoppingBagItems = JSON.parse(localStorage.getItem('shoppingBagItems')) || [];
        const couponExists = shoppingBagItems.some(item => item._id === this.couponId);

        if (couponExists) {
          this.showMessage('Coupon already saved');
        } else {
          axios.post(`${process.env.VUE_APP_API_URL}/api/shoppingCart/${userId.id}`, {
            couponId: this.couponId,
            name: this.dealTitle,
            couponCode: this.couponCode,
            couponImage: this.couponImage,
            category: this.category,
          }, {
            headers: {
              Authorization: `Bearer ${this.token}`
            }
          
          })
          .then(() => {
            let shoppingBagItems = JSON.parse(localStorage.getItem('shoppingBagItems')) || [];
            const item = { couponId: this.couponId, name: this.dealTitle, couponCode: this.couponCode, couponImage: this.couponImage, category: this.category };
            shoppingBagItems.push(item);
            localStorage.setItem('shoppingBagItems', JSON.stringify(shoppingBagItems));
            const event = new CustomEvent('update-shopping-bag', { detail: shoppingBagItems });
            window.dispatchEvent(event);
            this.showMessage('Coupon saved');
            this.isInShoppingBag = true; // Update the flag
          })
          .catch((error) => {
            console.error('Error saving to shopping cart:', error);
          });
        }
      }
    },
    async logInteraction(interactionType) {
      const userId = JSON.parse(localStorage.getItem('user'));
      if (!userId) {
        // Skip logging interactions if user is not logged in
        return;
      }
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/api/logInteraction`, {
          userId: userId.id, // Include the userId
          couponId: this.couponId,
          interactionType: interactionType
        });
      } catch (error) {
        console.error('Error logging interaction:', error);
      }
    },
    showMessage(text) {
      this.message = text;
      this.fading = false;
      setTimeout(() => {
        this.fading = true;
        setTimeout(() => {
          this.message = '';
          this.fading = false;
        }, 1000); // Duration of the fade-out effect
      }, 2000); // Duration the message stays visible before fading out
    },
    handleDealClick(event) {
      event.stopPropagation();
      if (!this.isLoggedIn) {
        this.$router.push('/login');
        window.scrollTo(0, 0);
      }
    },
    handleDealHover() {
      if (!this.isLoggedIn) {
        this.showFakeCode = true;
      }
    },
    handleDealMouseLeave() {
      this.showFakeCode = false;
    },
    copyCode(event) {
      event.stopPropagation();

      const textToCopy = this.couponCode;

      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // Avoid scrolling to bottom
      textArea.style.position = "fixed";
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.width = "2em";
      textArea.style.height = "2em";
      textArea.style.padding = "0";
      textArea.style.border = "none";
      textArea.style.outline = "none";
      textArea.style.boxShadow = "none";
      textArea.style.background = "transparent";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          this.copyButtonText = 'Copied';
          setTimeout(() => {
            this.copyButtonText = 'Copy';
          }, 2000); // Reset the text after 2 seconds
        } else {
          console.error('Fallback: Oops, unable to copy');
        }
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    },
    checkIfInShoppingBag() {
      const shoppingBagItems = JSON.parse(localStorage.getItem('shoppingBagItems')) || [];
      this.isInShoppingBag = shoppingBagItems.some(item => item.couponId === this.couponId);
    },
    removeCoupon(e) {
      e.stopPropagation();
      const user = JSON.parse(localStorage.getItem('user')).id;
      const name = this.dealTitle; // Ensure the name is included in the request
      axios.put(`${process.env.VUE_APP_API_URL}/api/shoppingCart/remove-from-shopping-bag/${user}`, { couponId: this.couponId, name }, {
        headers: {
          Authorization: `Bearer ${this.token}`
        } 
      })
        .then(() => {
          let shoppingBagItems = JSON.parse(localStorage.getItem('shoppingBagItems')) || [];
          shoppingBagItems = shoppingBagItems.filter(item => item.couponId !== this.couponId || item.name !== name);
          localStorage.setItem('shoppingBagItems', JSON.stringify(shoppingBagItems));
          const updateEvent = new CustomEvent('update-shopping-bag', { detail: shoppingBagItems });
          window.dispatchEvent(updateEvent);
          this.showMessage('Coupon removed');
          this.isInShoppingBag = false; // Update the flag
        })
        .catch((error) => {
          console.error('Error removing from shopping bag:', error);
        });
    },
    printCoupon() {
      const imageUrl = this.couponImageSrc; // Use the computed property for the image URL
      const printWindow = window.open('', '_blank');
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Incentive</title>
            <style>
              body {
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
              }
              img {
                max-width: 100%;
                max-height: 100vh;
                object-fit: contain;
              }
            </style>
          </head>
          <body>
            <img src="${imageUrl}" alt="Incentive">
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.onload = function() {
        printWindow.focus();
        printWindow.print();
        printWindow.onafterprint = function() {
          printWindow.close();
        };
      };
    },
    getStarClass(index) {
      if (this.rating === 0) {
        return 'blank';
      } else if (index <= Math.floor(this.rating)) {
        return 'filled';
      } else if (index === Math.ceil(this.rating) && this.rating % 1 !== 0) {
        return 'half-filled';
      } else {
        return 'blank';
      }
    }
  }
}
</script>


<style scoped>
.coupon-card { 
  max-width: 600px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex: 1 0 calc(25% - 10px);
  margin: 5px;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 50px;
  position: relative;
  cursor: pointer;
  background-color: white;
  padding-bottom: 3%;
}

.business-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.business-logo img {
  max-width: 80%;
  max-height: 100px;
  object-fit: contain;
}

.coupon-image {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coupon-image img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.coupon-content {
  padding: 1rem;
  text-align: left;
}

.coupon-header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.business-rating-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.business-name {
  margin: 0;
  font-size: 17px;
  color: #333;
  font-weight: bold;
}

.bussiness-address{
  font-size: 15px;
  color: #505050;
  font-weight: 600;
  margin-bottom: 12px;
  font-family: 'Roboto', sans-serif;
}

.deal-name {
  margin: 0;
  font-size: 30px;
  color: red;
  line-height: 1.4;
  margin-bottom: 8px;
}

.star-rating {
  display: flex;
  align-items: center;
  gap: 2px;
}

.star {
  color: #FFD700;
  font-style: normal;
  font-size: 18px;
  line-height: 1;
  display: flex;
  align-items: center;
}

.rating-number {
  font-size: 15px;
  color: #333;
  font-weight: 600;
  padding-left: 5%
}

.get-deal-button-container {
  position: relative;
  margin: 20px 0;
  overflow: hidden;
}

.get-deal-button {
  display: inline-block;
  padding: 12px 20px;
  background-color: #4C6B30;
  color: white;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: transform 0.3s ease;
  z-index: 2;
  position: relative;
}

.get-deal-button:hover {
  transform: translateX(-5px);
}

.dashed-box {
  position: absolute;
  top: 50%;
  right: 190px;
  transform: translateY(-50%) translateX(50%);
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  border: 1px dashed #ccc;
  padding: 9px 10px;
  background-color: #fff;
  z-index: 1;
  width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.coupon-code-container {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.coupon-code-box {
  font-size: 20px;
  color: #333;
  white-space: nowrap;
  border: 1px dashed #ccc;
  padding: 5px 10px;
  background-color: #fff;
  width: auto; /* Changed from fixed width to auto */
  max-width: 100%; /* Ensure it doesn't overflow */
  overflow: hidden;
  text-overflow: ellipsis;
}

.copy-button {
  margin-left: 10px;
  padding: 10px 10px;
  background-color: #4C6B30;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  outline: none;
}

.coupon-actions {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 1rem;
}

.action-button {
  border: none;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
  background-color: #eff1f3;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.action-button.remove {
  background-color: #ff4d4d;
  color: #fff;
}

.action-button.remove:hover {
  background-color: #ff0000;
}

.action-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.icon {
  width: 25px;
  height: 25px;
}

.action-button[title]:hover::after {
  content: attr(title);
  position: absolute;
  bottom: calc(100% + 10px);
  left: 95%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 0.5rem;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 0.8rem;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s;
}

.message {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 0.8rem;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.message.fade {
  opacity: 0;
}

@media (max-width: 1024px) {
  .dashed-box {
    right: 175px;
    transform: translateY(-50%) translateX(50%);
  }
}

@media (max-width: 768px) {
  .coupon-card {
    max-width: none;
    width: 90%;
    min-height: 400px;
    max-height: 600px;
  }

  .coupon-image img {
    height: auto;
  }

  .coupon-content {
    padding: 1rem;
  }

  .deal-details,
  .coupon-actions,
  .get-deal-button-container,
  .coupon-code-container {
    flex: 1;
  }

  .action-button[title="Print Incentive"] {
    display: none;
  }
}
</style>
